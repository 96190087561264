import React from "react";

function Main() {
  return (
    <div className="bgimg">
      <h1 className="middle">COMING SOON!</h1>
    </div>
  );
}

export default Main;
